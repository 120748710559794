import * as React from "react";
import { Image, Item, Icon, IconProps, ItemContent } from "semantic-ui-react";

interface GaroonDevInfo {
  self:boolean; // true: open self page, false: open new page
  image: string;
  url: string;
  header: string;
  icon: IconProps["name"];
}

const GaroonDevInformations: GaroonDevInfo[] = 
  [
      {
        self: true,
        image: "/images/Cybozu-Garoon-Dev/qrcode_CybozuGaroonDev.png",
        url: "https://tech.cybozu.io/Cybozu-Garoon-Dev/",
        header: "このページ",
        icon: "linkify",
      },
      {
        self: false,
        image: "/images/Cybozu-Garoon-Dev/qrcode_Garoon.png",
        url: "https://blog.cybozu.io/archive/category/Garoon",
        header: "Cybozu Inside Out (Blog) - Category: Garoon",
        icon: "linkify",
      },
      {
        self: false,
        image: "/images/Cybozu-Garoon-Dev/qrcode_PHP.png",
        url: "https://blog.cybozu.io/archive/category/PHP",
        header: "Cybozu Inside Out (Blog) - Category: PHP",
        icon: "linkify",
      },
      {
        self: true,
        image: "/images/Cybozu-Garoon-Dev/qrcode_SlidesGaroon.png",
        url: "https://tech.cybozu.io/slides/tags/garoon/",
        header: "CybozuTech (Portal Site) - Slides: Garoon",
        icon: "linkify",
      },
      {
        self: true,
        image: "/images/Cybozu-Garoon-Dev/qrcode_SlidesPHP.png",
        url: "https://tech.cybozu.io/slides/tags/php/",
        header: "CybozuTech (Portal Site) - Slides: PHP",
        icon: "linkify",
      },
      {
        self: false,
        image: "/images/Cybozu-Garoon-Dev/qrcode_VideosGaroon.png",
        url: "https://www.youtube.com/@cybozuinsideout/search?query=Garoon",
        header: "YouTube Cybozu Inside Out - Videos: Garoon",
        icon: "linkify",
      },
      {
        self: false,
        image: "/images/Cybozu-Garoon-Dev/qrcode_VideosPHP.png",
        url: "https://www.youtube.com/@cybozuinsideout/search?query=PHP",
        header: "YouTube Cybozu Inside Out - Videos: PHP",
        icon: "linkify",
      },
    ];
  


const GaroonDevList: React.FC = () => {
  return ( 
    <>
      {/* Garoon開発の情報 */}
      {GaroonDevInformations.map((info) => {
        return (
          <>
          <Item className="nolink-item">
            <Item.Content>
              {info.self ? <Item.Header href={info.url}>{info.header}</Item.Header> : 
              <Item.Header href={info.url} target="_blank" rel="noreferrer noopener">{info.header}</Item.Header>}
              <Item.Description>
                <Image src={info.image} size='tiny' verticalAlign='middle' /> 
                <Icon name={info.icon} />{info.self ? <Item.Content verticalAlign='middle' href={info.url}>{info.url}</Item.Content> :
                 <Item.Content verticalAlign='middle' href={info.url} target="_blank" rel="noreferrer noopener">{info.url}</Item.Content>}
              </Item.Description>
            </Item.Content>
          </Item>
          </>
            )})}
      </>
  );
}

export default GaroonDevList;
